/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

//var mapStyles = [{"featureType":"all","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"all","elementType":"geometry","stylers":[{"visibility":"simplified"}]},{"featureType":"all","elementType":"geometry.fill","stylers":[{"visibility":"off"}]},{"featureType":"all","elementType":"geometry.stroke","stylers":[{"visibility":"off"}]},{"featureType":"all","elementType":"labels","stylers":[{"visibility":"simplified"}]},{"featureType":"all","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"all","elementType":"labels.text.fill","stylers":[{"color":"#4dae88"},{"gamma":"10.00"},{"visibility":"off"}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#00378b"}]},{"featureType":"landscape","elementType":"geometry.fill","stylers":[{"visibility":"on"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"simplified"},{"color":"#4aadd6"}]}];
var mapStyles = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#f5f5f5"
      }
    ]
  },
  {
    "featureType": "administrative.land_parcel",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#ffffff"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#dadada"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "transit.line",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#e5e5e5"
      }
    ]
  },
  {
    "featureType": "transit.station",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#eeeeee"
      }
    ]
  },
  {
    "featureType": "water",
    "stylers": [
      {
        "color": "#5fd2dc"
      },
      {
        "visibility": "simplified"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4AADD6"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  }
];

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {

    /* ==========================================================================
        ALL PAGES
       ========================================================================== */

    'common': {
      init: function () {
        // JavaScript to be fired on all pages


        if ($(window).width() > 1024) {
          $('.equal-heights').each(function () {
            $(this).children('.col-sm-6').matchHeight({
              byRow: false
            });
          });
        }


        var timelineItem = $('.c-timeline__item'),
          timelineList = $('.c-timeline__list'),
          timelineWidth = 300,
          exitR = false,
          exitL = true,
          animating = false;


        // Set the width of the timeline
        // ------------------------------------------------------------------
        $('.c-timeline__list').width(timelineItem.length * timelineWidth);


        // Move the timeline forwards
        // ------------------------------------------------------------------
        $('.c-timeline__nav__next').on('click', 'a', function (event) {

          exitL = true;

          // Remove diabled class from button
          if ($('.c-timeline__nav__prev a').hasClass('disabled')) {
            $('.c-timeline__nav__prev a').removeClass('disabled');
          }

          event.preventDefault();

          if (!exitR && !animating) {

            animating = true;

            TweenMax.to(timelineList, 0.5, {
              x: "-=" + timelineWidth + "px",

              onComplete: function () {

                // console.log( "Transform: " + Math.abs(this.target[0]._gsTransform.x - timelineWidth ) );
                // console.log( "Width: " + timelineList.width() );

                animating = false;

                if (Math.abs(this.target[0]._gsTransform.x - timelineWidth) >= timelineList.width()) {

                  exitR = true;

                  $('.c-timeline__nav__next a').addClass('disabled');

                }

              }

            });

          }

        });


        // Move the timeline backwards
        // ------------------------------------------------------------------
        $('.c-timeline__nav__prev').on('click', 'a', function (event) {

          if ($('.c-timeline__nav__next a').hasClass('disabled')) {
            $('.c-timeline__nav__next a').removeClass('disabled');
          }

          exitR = false;

          event.preventDefault();

          if (exitL && !animating) {

            animating = true;

            TweenMax.to(timelineList, 0.5, {
              x: "+=" + timelineWidth + "px",

              onComplete: function () {

                animating = false;

                if (Math.ceil(Math.abs(this.target[0]._gsTransform.x - timelineWidth)) <= timelineWidth) {
                  exitL = false;
                  $('.c-timeline__nav__prev a').addClass('disabled');
                }
              }

            });



          }

        });







        // MOBILE MENU OVERLAY
        // -----------------------------------------------------------
        $(window).on("load resize", function () {

          var $gHeader = $('#g-header'),
            $mobileNav = $('#mobile-nav'),
            $winHeight = $(window).height(),
            $gHeaderHeight = $gHeader.outerHeight(true);

          $mobileNav.height($winHeight - $gHeaderHeight);

        });

        $("#mobile-nav-toggle").on("click", function (e) {
          e.preventDefault();
          $(this).toggleClass('js-active');
          $('#mobile-nav').slideToggle(200);
        });



        // LOGIN POPUP
        // -----------------------------------------------------------
        $('#user_login').attr('placeholder', 'Enter your email');
        $('#user_pass').attr('placeholder', 'Enter your password');

        $('.login-remember').find('label').append('<span class="login__checkbox">Stay logged in</span>');

        $('#rememberme').prop('checked', true);

        $('.menu-login-sign-up').on("click", function (e) {
          e.preventDefault();
          $('.modal').modal('show');
        });

        // Selectors
        // -----------------------------------------------------------
        var $navItem = $('#menu-primary-navigation .main-nav__item'),
          $navLinkItem = $('.main-nav__link'),
          $hoverLine = $('#hover-line'),
          $subNav = $('.sub-nav__list'),
          $primaryNavigation = $('#menu-primary-navigation'),
          timer;

        // Document ready
        // -----------------------------------------------------------
        $(function () {

          // Slide nav
          $navItem.hover(
            function () {
              // $(this).children('.sub-nav__list').stop().slideToggle(250);
              // $(this).toggleClass('active');

              var thisWidth = $(this).children($navLinkItem).width(),
                thisLeft = $(this).offset();

              clearTimeout(timer);

              $hoverLine.css({
                'width': thisWidth + 'px',
                'left': thisLeft.left + 'px'
              });
            },
            function () {
              // $(this).children('.sub-nav__list').stop().slideToggle(200);
              // $(this).toggleClass('active');

              timer = setTimeout(function () {
                $hoverLine.css({
                  'width': '100%',
                  'left': '0px'
                });
              }, 200);

            }
          );

        });

        // Load resize
        // -----------------------------------------------------------
        $(window).on('load resize', function () {

          var $windWidth = $(window).width();

          $navItem.each(function () {
            var $item = $(this);

            var $navItemOffsetLeft = $item.offset().left,
              $navItemOffsetRight = ($(window).width() - ($item.offset().left + $item.width())),
              $dummyOffsetList = ($(window).width() - ($item.offset().left + 325)),
              $primaryNavOffset = ($(window).width() - ($primaryNavigation.offset().left + $primaryNavigation.outerWidth()));

            $firstSubNavList = $item.children('.sub-nav__list');

            $firstSubNavList
              .toggleClass('sub-nav__list--right', $dummyOffsetList < $primaryNavOffset);

            $firstSubNavList
              .find('.sub-nav__list')
              .toggleClass('sub-nav__list--right', $firstSubNavList.offset().left + $firstSubNavList.width() + 325 > window.innerWidth - $primaryNavOffset);

          });
        });


        $('.sub-pages__wrapper')
          .find('.page_item_has_children')
          .each(function (i, el) {
            var $el = $(el);
            $el.children('a').after('<a href="#" class="menu-toggler"></a>');
            $el.children('.menu-toggler').click(function (event) {
              event.preventDefault();
              var $this = $(this);
              $(this)
                .toggleClass('menu-toggler--close')
                .siblings('.children').slideToggle();
              return false;
            });
          });

        if (typeof tribeEventsSingleMap !== 'undefined') {
          tribeEventsSingleMap.pin_url = "/wp-content/themes/palau/dist/images/pin-hover-01.png";
        }

        $('body').on('map-created.tribe', function (event, map, container, mapOptions) {
          mapOptions.styles = mapStyles;
          map.setOptions(mapOptions);
        });

        $('#modal-send-file').on('show.bs.modal', function (event) {
          var $modal = $(this);
          var $fileField = $modal.find('#input_6_11');
          $fileField.val($(event.relatedTarget).data('filePath'));
        });
      },
      finalize: function () {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },


    /* ==========================================================================
        HOMEPAGE
       ========================================================================== */
    'home': {
      init: function () {
        // JavaScript to be fired on the home page

        // BACKSTRETCH
        // ---------------------------------------------------------------
        var slides = $('.o-slideshow__img').data('slides');
        $('.o-slideshow__img').backstretch(slides, {
          fade: 400,
          duration: 6000
        });

        $(window).on("backstretch.before", function (e, instance, index) {
          $('.o-slideshow__text').fadeOut();
          $('#o-slideshow__text-' + index).fadeIn();
        });

        // ANIMATIONS
        // ---------------------------------------------------------------


        /*
        * Used as a callback function
        * for the newsfeed
        */
        var StaggerOpacity = function ($element) {

          $element.each(function (i) {
            var $item = $(this);
            setTimeout(function () {
              $item.animate({ "opacity": 1 }, 300);
            }, 250 * i);
          });

        };


        /*
        * Define a basic config for the scrollreveal
        * and then add basic overrides
        */
        var config = {
          // Animation
          origin: 'bottom',
          distance: '20px',
          duration: 500,
          delay: 0,
          rotate: { x: 0, y: 0, z: 0 },
          opacity: 0,
          scale: 0.9,
          easing: 'cubic-bezier( 0.6, 0.2, 0.1, 1 )',

          // Options
          container: null,
          mobile: false,
          reset: false,
          useDelay: 'always',
          viewFactor: 0.20,
          viewOffset: { top: 0, right: 0, bottom: 0, left: 0 }
        };


        var slideshowImg = {
          distance: "3vw",
          origin: "right",
          duration: 700,
          scale: 1
        };

        var slideshowText = {
          origin: "left",
          distance: "3vw",
          duration: 300,
          delay: 400,
          scale: 1
        };

        var featuredSinglePageImg = {
          origin: "left",
          distance: "2vw",
          duration: 200,
          viewFactor: 0.40,
          delay: 300,
          scale: 1
        };

        var featuredSinglePageText = {
          origin: "right",
          distance: "2vw",
          duration: 400,
          delay: 400,
          viewFactor: 0.40,
          scale: 1
        };

        window.sr = ScrollReveal(config);


        if (!sr.tools.isMobile()) {

          /*
          * The actual animation:
          */

          sr.reveal(".o-slideshow", slideshowImg);
          sr.reveal(".o-slideshow__content", slideshowText);
          sr.reveal(".o-hero-banner__img", featuredSinglePageImg);
          sr.reveal(".o-content__preview", featuredSinglePageText);
          sr.reveal(".js-animate-1", { delay: 150, scale: 1, distance: "0px", viewFactor: 0.50 });
          sr.reveal(".js-animate-2", { delay: 300, scale: 1, distance: "0px", viewFactor: 0.50 });
          sr.reveal(".js-animate-3", { delay: 450, scale: 1, distance: "0px", viewFactor: 0.50 });
          sr.reveal(".js-animate-4", { delay: 600, scale: 1, distance: "0px", viewFactor: 0.50 });
          sr.reveal(".o-content", { scale: 1 });



        }




      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    /* ==========================================================================
        DEFAULT PAGE
       ========================================================================== */
    'page': {
      init: function () {


        $('.single-page__icon').add('.u-heading-box__img').height($('.u-heading-box').outerHeight() - $('.o-single-heading').outerHeight() + 1);


        $('.c-toggle__title').on("click", function (e) {

          e.preventDefault();

          var $toggleBlock = $(this).closest('.c-toggle__block').toggleClass('active');
          var $toggleBody = $(this).next('.c-toggle__text').children('.c-toggle__body').slideToggle(280);

          // Close Other Boxes
          var $otherBoxes = $('.c-toggle__body:visible').not($toggleBody).not($toggleBody.parents('.c-toggle__body:visible'));
          if ($otherBoxes) {
            $otherBoxes.slideUp(280);
            $('.c-toggle__block').not($toggleBlock).not($toggleBlock.parents('.c-toggle__block')).removeClass('active');
          }

          // Close Inner Boxes
          if ($('.c-toggle__body--inner--text:visible')) {
            $('.c-toggle__title--inner').removeClass('inn-active');
            $('.c-toggle__body--inner--text').slideUp(280);
          }

        });

        // May be deprecated.
        $('.c-toggle__title--inner').on("click", function (e) {

          e.preventDefault();

          $(this).toggleClass('inn-active');

          var thisNext = $(this).next('.c-toggle__body--inner--text');

          if ($('.c-toggle__body--inner--text:visible').not(thisNext)) {
            $('.c-toggle__title--inner').not($(this)).removeClass('inn-active');
            $('.c-toggle__body--inner--text').not(thisNext).slideUp(280);
          }

          $(this).next('.c-toggle__body--inner--text').slideToggle(280);

        });



        $('.wpcf7').click(function (event) {
          event.stopPropagation();
        });

        $('.c-reply-toggle').on('click', function (e) {
          e.preventDefault();
          $(this).next('.c-reply').slideToggle('fast');
          $(this).find('.fa-chevron-down').toggleClass('active');
        });


        // GALLERY
        // -----------------------------------------------------------

        // ----------------------
        // Selectors Photoswipe
        // ----------------------
        var $galleryWrap = $('.carousel-inner');
        var $galleryItems = $galleryWrap.find('a');
        var getGalleryItems = function () {

          var items = [];

          $galleryItems.each(function () {
            var $href = $(this).attr('href'),
              $size = $(this).data('size').split('x'),
              $width = $size[0],
              $height = $size[1],
              item = {
                src: $href,
                w: $width,
                h: $height
              };
            items.push(item);
          });

          //console.log(items)
          return items;
        };

        // -------------------------------------------------------
        // SELECTORS
        // -------------------------------------------------------
        var $gallery = $('#gallery-carousel'),
          $galleryFs = $('#gallery-carousel-fs'),
          $item = $gallery.find('.item'),
          $activeItem = $gallery.find('.active'),
          $gallery_item = $gallery.find('.c-carousel__image'),
          $body = $('body'),
          $pswp = $('.pswp')[0],
          totalItems = $item.length,
          currentIndex = $activeItem.index() + 1;

        $('#js-carousel-index').html(' ' + currentIndex + ' / ' + totalItems + ' ');

        $gallery.on('slid.bs.carousel', function () {
          currentIndex = $('.item.active').index() + 1;
          $('#js-carousel-index').html(' ' + currentIndex + ' / ' + totalItems + ' ');
        });

        $gallery_item.on("click", function (e) {
          $gallery.carousel("pause");
          e.preventDefault();

          var $index = $(this).index();
          var options = {
            index: $index,
            bgOpacity: 0.9,
            showHideOpacity: false
          };

          var galleryFs = new PhotoSwipe($pswp, PhotoSwipeUI_Default, getGalleryItems(), options);
          galleryFs.init();
        });


      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    /* ==========================================================================
        CLASS SUSPENSIONS
       ========================================================================== */
    'class_suspensions': {
      init: function () {

        /**
          * Make :contains case insensitive
          */
        $.expr[":"].contains = $.expr.createPseudo(function (arg) {
          return function (elem) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
          };
        });

        /**
          * Track search input changes.
          */
        $('#class-search input').on('input', function () {

          var value = $(this).val();

          // Find table cells that contain the value supplied in the input
          var $selectedField = $('.suspension__content:contains(' + value + ')');
          var $selectedFieldParent = $selectedField.parents('.suspension');


          // Create a case-insensitive regex from the input's value
          var regex = new RegExp(value, 'gi');

          // Un-highlight previously highlighted strings in the TDs
          $('.highlighted').contents().unwrap();

          if (value !== '') {

            // Highlight strings
            $selectedField.html(function (_, html) {
              return html.replace(regex, '<span class="highlighted">$&</span>');
            });

          }

          // Only show the fields that contain the input's value
          $selectedFieldParent.show();
          $('.suspension').not($selectedFieldParent).hide();


        });








      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    /* ==========================================================================
        SPLIT FILES
       ========================================================================== */
    'page_template_template_split_files': {
      init: function () {

        /**
          * Make :contains case insensitive
          */
        $.expr[":"].contains = $.expr.createPseudo(function (arg) {
          return function (elem) {
            return $(elem).text().toUpperCase().indexOf(arg.toUpperCase()) >= 0;
          };
        });

        /**
          * Track search input changes.
          */
        $('#class-search input').on('input', function () {

          var value = $(this).val();

          // Find table cells that contain the value supplied in the input
          var $selectedField = $('.c-button-link__text:contains(' + value + ')');
          var $selectedFieldParent = $selectedField.parents('.log-files');


          // Create a case-insensitive regex from the input's value
          var regex = new RegExp(value, 'gi');

          // Un-highlight previously highlighted strings in the TDs
          $('.highlighted').contents().unwrap();

          if (value !== '') {

            // Highlight strings
            $selectedField.html(function (_, html) {
              return html.replace(regex, '<span class="highlighted">$&</span>');
            });

          }

          // Only show the fields that contain the input's value
          $selectedFieldParent.show();
          $('.log-files').not($selectedFieldParent).hide();

        });


      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },

    /* ==========================================================================
        Careers Template
       ========================================================================== */
    'page_template_template_careers': {
      init: function () {

        $('.o-article__more').on('click', function (event) {
          event.preventDefault();
          $(this).parents('.c-toggle__block').toggleClass('active');
          $(this).closest('.c-toggle__excerpt').next('.c-toggle__body').slideToggle(280);
        });



      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },


    /* ==========================================================================
        Economical offer
       ========================================================================== */
    'economical_offer': {
      init: function () {


        $('#nav-area').on('input', function (event) {

          if ($(this).val() === '') {
            $('#nav-area-spec-field').slideUp('300');
          } else {
            $('#nav-area-spec-field').slideDown('300');
          }

        });



      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },




    /* ==========================================================================
        MAP LOCATOR
       ========================================================================== */
    'map_locator': {
      init: function () {

        // MAP LOCATOR
        // -------------------------------------------------------------------------
        /*
        *  center_map
        *
        *  This function will center the map, showing all markers attached to this map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param map (Google Map object)
        *  @return  n/a
        */

        function center_map(map) {

          // vars
          var bounds = new google.maps.LatLngBounds();

          // loop through all markers and create bounds
          $.each(map.markers, function (i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
          });

          // only 1 marker?
          if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(16);
          } else {
            // fit to bounds
            map.fitBounds(bounds);
          }

        }

        function closeInfoWindows() {
          if (infowindow) {
            infowindow.close();
          }
          infowindow = new google.maps.InfoWindow();
        }

        var prev_infowindow;

        /*
        *  add_marker
        *
        *  This function will add a marker to the selected Google Map
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param $marker (jQuery element)
        *  @param map (Google Map object)
        *  @return  n/a
        */

        function add_marker($marker, map) {
          //IW
          prev_infowindow = false;

          // var
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));

          var icon1 = "/wp-content/themes/palau/dist/images/pin-01.png";
          var icon2 = "/wp-content/themes/palau/dist/images/pin-hover-01.png";

          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: icon2
            //label		: $marker.html().substring(20,25)
          });

          // google.maps.event.addListener(marker, 'mouseover', function() {
          //   marker.setIcon(icon2);
          // });
          // google.maps.event.addListener(marker, 'mouseout', function() {
          //   marker.setIcon(icon1);
          // });

          // add to array
          map.markers.push(marker);

          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html(),
              maxWidth: 300
              //pixelOffset: new google.maps.Size(0, 60)
            });

            // center the map when infowindow is closed
            google.maps.event.addListener(infowindow, 'closeclick', function () {
              //center_map( map );
              //map.setZoom(5);
              //marker.setIcon(icon1);
            });

            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function () {

              //IW
              if (prev_infowindow) {
                prev_infowindow.close();
              }

              //closeInfoWindows();
              infowindow.open(map, marker);
              //map.setZoom(14);
              map.setCenter(marker.getPosition());
              //IW
              prev_infowindow = infowindow;
            });

          }

        }


        function initMapLocal(mapGiven) {

          // var map2 = new google.maps.Map(document.getElementById('map-container'), {
          // disableDefaultUI: true,
          // zoomControl: true,
          // scrollwheel: true,
          // zoom    : 3,
          // minZoom:  2,
          // maxZoom:  20,
          // center    : new google.maps.LatLng(0, 0),
          // mapTypeId : google.maps.MapTypeId.ROADMAP,
          // styles    : mapStyles
          // });

          // Create an array of alphabetical characters used to label the markers.
          var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZAAABACADAEAFAGAHAIAJAKALAMANAOAPAQARASATAUAVAWAXAYAZ';

          // Add some markers to the map.
          // Note: The code uses the JavaScript Array.prototype.map() method to
          // create an array of markers based on a given "locations" array.
          // The map() method here has nothing to do with the Google Maps API.
          // var markers = map.markers.map(function(marker, i) {
          // return new google.maps.marker({
          // position  : marker.position,
          // label	  : labels[i % labels.length]
          // });
          // });

          // for (i = 0; i < markers.length; i++) { 
          // markers(i).label === markers(i).html();
          // }

          // Add a marker clusterer to manage the markers.
          var markerCluster = new MarkerClusterer(mapGiven, mapGiven.markers,
            { imagePath: '/wp-content/themes/palau/assets/images/m' });
        }



        /*
        *  new_map
        *
        *  This function will render a Google Map onto the selected jQuery element
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 4.3.0
        *
        *  @param $el (jQuery element)
        *  @return  n/a
        */

        function new_map($el) {
          var styles = {
            default: null,
            hide: [
              {
                featureType: 'poi.business',
                stylers: [{ visibility: 'off' }]
              },
              {
                featureType: 'transit',
                elementType: 'labels.icon',
                stylers: [{ visibility: 'off' }]
              }
            ]
          };

          // var
          var $markers = $el.find('.marker');

          // vars
          var args = {
            disableDefaultUI: true,
            zoomControl: true,
            scrollwheel: true,
            zoom: 3,
            minZoom: 2,
            maxZoom: 20,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.SATELITE,
            styles: mapStyles
          };

          // create map
          var map = new google.maps.Map($el[0], args);

          //map.setOptions({styles: styles.hide});

          /*map.setOptions({styles: {
          featureType: 'poi.business',
          elementType: 'all',
          stylers: [{visibility: 'off'}]
          }});*/

          /*map.setOptions({styles: {
          featureType: 'transit',
          elementType: 'labels.icon',
          stylers: [{visibility: 'off'}]
          }});*/
          // map.setOptions({styles: });
          // add a markers reference
          map.markers = [];

          // add markers
          $markers.each(function () {
            add_marker($(this), map);
          });

          // center map
          center_map(map);

          // return
          //return map;
          initMapLocal(map);



        }



        /*
        *  This function will render each map when the document is ready (page has loaded)
        *
        *  @type  function
        *  @date  8/11/2013
        *  @since 5.0.0
        *
        *  @param n/a
        *  @return  n/a
        */

        var map = null;

        $('.irs-map').each(function () {
          // create map
          map = new_map($(this));

        });


        $(window).resize(function () {
          center_map(map);
        });


        var locations = [];




        // MAP LOCATOR -------------------------------------------------------------------

      }
    }

	/* ==========================================================================
    MANAGEMENT
   ========================================================================== */
    //'management': {
    //  init: function () {

    //    // MANAGEMENT EXPAND COLLAPSE
    //    // -------------------------------------------------------------------------

    //    function showSelf() {

    //      for (var i = 1; i < 3; i++) {
    //        $('#bio' + i + ' .exp-col a').on('click', function () {
    //          if (this) {
    //            console.log($('#bio' + i + ' .cv-p').hasClass('exp'));
    //            if ($('#bio' + i + ' .cv-p').hasClass('exp')) {
    //              $('#bio' + i + ' .cv-p').removeClass('exp');
    //            } else {
    //              $('#bio' + i + ' .cv-p').addClass('exp');
    //            }
    //          }
    //          return false;
    //        });
    //      }

    //      //$($this).parent().parent().hide();
    //      //return false;
    //    }

    //    showSelf();


    //    // MANAGEMENT EXPAND COLLAPSE -------------------------------------------------------------------

    //  },
    //  finalize: function () {
    //    // JavaScript to be fired on the home page, after the init JS

    //  }
    //}

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  //$(document).ready(function(){
				 // $(".woof_sid_auto_shortcode").addClass("col-sm-4");
				 // $(".woocommerce-page.woof_shortcode_output").addClass("col-sm-8");
			 //});

})(jQuery); // Fully reference jQuery after this point.
